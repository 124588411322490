// How It Works: Data Flow
// Data fetched by RTK Query:

// When the getUsers query is executed, the response is transformed and normalized using usersAdapter.setAll().
// The normalized result (with ids and entities) is stored in RTK Query's internal cache, under the api.queries slice of Redux state.
// selectUsersResult extracts the query result:

// selectUsersResult is a selector that extracts the query result from RTK Query's cache.
// selectUsersData extracts the data field:

// selectUsersData uses the result from selectUsersResult to extract the data field (the normalized user data).
// If the query has not yet succeeded, it falls back to an empty initialState.
// getSelectors from the entity adapter:

// The usersAdapter.getSelectors() method creates several selectors that can access specific parts of the normalized data from the output of selectUsersData.

// How Do the Selectors Get the Data?
// The selectors generated by usersAdapter.getSelectors() are configured to look at the right part of your Redux store.

// When you call getSelectors(), you provide a function that tells the adapter where to find the data in your Redux state

// The function passed to getSelectors():
// (state) => selectUsersData(state) ?? initialState
// This function tells the selectors where to look in your Redux state.
// selectUsersData is a memoized selector that extracts the data field from RTK Query’s cache:
// javascript
// Copy code
// const selectUsersData = createSelector(
//   selectUsersResult,
//   (usersResult) => usersResult?.data // Extract the normalized state (ids & entities)
// );
// If the query result is not available, it falls back to initialState (an empty normalized state).



import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => '/users',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedUsers = responseData.map(user => {
                    user.id = user._id
                    return user
                });
                return usersAdapter.setAll(initialState, loadedUsers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'User', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'User', id }))
                    ]
                } else return [{ type: 'User', id: 'LIST' }]
            }
        }),
        addNewUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        updateUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        deleteUser: builder.mutation({
            query: ({ id }) => ({
                url: `/users`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        registerNewUser: builder.mutation({
            query: initialUserData => ({
                url: '/register',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
    }),
})

export const {
    useGetUsersQuery,
    useAddNewUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useRegisterNewUserMutation
} = usersApiSlice

// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select()

// creates memoized selector
const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data 
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds
    // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState)